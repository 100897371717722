import call from "@/service/http";
import AuthConstants from "./authConstants";
import AuthService from "./authService";
import { EventBus } from "@/utils/eventBus";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    alert: {
      status: "",
      message: "",
      user: {},
    },
    questions: [],
    survey_name: "",
  },
  mutations: {
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },

    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    alert: (state) => state.alert,
    authGetters: (state) => (val) => state[val],
  },
  actions: {
    login: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", AuthConstants.login, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            EventBus.$emit("otp-verification");
            commit("SET_ALERT", {
              status: "success",
              message: res.data.status_desc.concat(
                " sent to ",
                res.data.data.phone
              ),
            });
          } else {
            if (res.data.status_code === 1001) {
              EventBus.$emit("otp-verification");
              commit("SET_ALERT", {
                status: "success",
                message: res.data.status_desc,
              });
            } else {
              if (res.data.status_code === 1002) {
                commit("SET_ALERT", {
                  status: "error",
                  message: "Invalid Credentials",
                });
              } else {
                if (res.data.status_code === 1003) {
                  commit("SET_ALERT", {
                    status: "error",
                    message: res.data.status_desc,
                  });
                }
              }
            }
          }

          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong. Try Again Later",
          });
        });
    },

    register: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.register, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("SET_ALERT", {
              status: "success",
              message: "User Registered Successfully, Proceed to Login",
            });
            EventBus.$emit("redirectBack");
          } else {
            commit("SET_ALERT", {
              status: "error",
              message: res.data.status_desc,
            });
          }
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong. Try Again Later",
          });
        });
    },

    verifyOtp: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", AuthConstants.verifyOtp, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          if (res.data.status_code === 1000) {
            AuthService.login(
              res.data.data.token,
              res.data.data.user_id,
              res.data.data.id_number,
              res.data.data.phone
            );
          } else {
            commit("SET_ALERT", {
              status: "error",
              message: res.data.status_desc,
            });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong",
          });
        });
    },
    submitQuery: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", AuthConstants.submitQuery, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          if (res.data.status_code === 1000) {
            commit("SET_ALERT", {
              status: "success",
              message:
                "Query succcessfully Submitted. Wait for response on your Mobile Phone",
            });
            window.location.reload();
          } else {
            commit("SET_ALERT", {
              status: "error",
              message: res.data.status_desc,
            });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong",
          });
        });
    },

    getSurveyQuestions: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", AuthConstants.getSurveyQuestions, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          if (res.data.status_code === 1000) {
            commit("MUTATE", { state: "questions", value: res.data.data });
            commit("MUTATE", {
              state: "survey_name",
              value: res.data.survey_name,
            });
          } else {
            commit("SET_ALERT", {
              status: "error",
              message: res.data.status_desc,
            });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong",
          });
        });
    },
    submitQuestions: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", AuthConstants.submitQuestions, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          if (res.data.status_code === 1000) {
            EventBus.$emit("successQuestions", res.data.status_desc);
          } else {
            EventBus.$emit("error", res.data.status_desc);
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("error", "Something Went Wrong");
        });
    },
    validate: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.validate, data)
        .then((res) => {
          if (res.data.status_code === 1002) {
            commit("SET_ALERT", {
              status: "success",
              message: res.data.status_desc.concat(".Proceed to Login Below"),
            });
          } else {
            if (res.data.status_code === 1000) {
              commit("SET_ALERT", {
                status: "success",
                message: "Please Wait ..",
              });
              AuthService.setUser(res.data.data);

              EventBus.$emit("redirecttoDetailsPage");
            } else {
              commit("SET_ALERT", {
                status: "error",
                message: "Could Not Be validated",
              });
            }
          }
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong.Try Again Later",
          });
        });
    },
    resendOTP: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", AuthConstants.forgot, data)
        .then((res) => {
          console.log(res);
          if (res.data.status_code === 1000) {
            commit("SET_ALERT", {
              status: "success",
              message: res.data.status_desc,
            });
          } else {
            if (res.data.status_code === 1001) {
              EventBus.$emit("otp-verification");
              commit("SET_ALERT", {
                status: "success",
                message: res.data.status_desc,
              });
            } else {
              if (res.data.status_code === 1002) {
                commit("SET_ALERT", {
                  status: "error",
                  message: "Invalid Credentials",
                });
              }
            }
          }

          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong",
          });
        });
    },

    registerLindaMamaHospital: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.LINDAMAMAHOS, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("SET_ALERT", {
              status: "success",
              message: res.data.status_desc.concat("..Redirecting to Login"),
            });
            EventBus.$emit("redirecttoLoginPage");
          } else {
            commit("SET_ALERT", {
              status: "error",
              message: res.data.status_desc,
            });
          }
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong. Try Again Later",
          });
        });
    },

    selfRegisterLindaMama: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.SELFLINDAMAMAHOS, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("SET_ALERT", {
              status: "success",
              message: res.data.status_desc,
            });
          } else {
            commit("SET_ALERT", {
              status: "error",
              message: res.data.status_desc,
            });
          }
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong",
          });
        });
    },
    guardianRegisterLindaMama: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.GUARDLINDAMAMAHOS, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("SET_ALERT", {
              status: "success",
              message: res.data.status_desc,
            });
          } else {
            commit("SET_ALERT", {
              status: "error",
              message: res.data.status_desc,
            });
          }
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong.Try Again Later",
          });
        });
    },
    getEcitizenUser: ({ commit }, data) => {
      axios
        .post(
          "https://ecitizenkenya.tililtechnologies.com/api_ecitizen_getuser",
          data
        )
        .then((res) => {
          if (res.data.status_code === 1000) {
            AuthService.login(
              res.data.data.surname,
              res.data.data.id,
              res.data.data.id_number,
              res.data.data.mobile_number
            );
          }
        })
        .catch(() => {
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong",
          });
        });
    },

    ecitizenLogin: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      axios
        .post(
          "https://ecitizenkenya.tililtechnologies.com/api_ecitizen_access",
          data
        )
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("SET_ALERT", {
              status: "success",
              message: "Wait For login",
            });
            dispatch("getEcitizenUser", {
              access_token: res.data.data.access_token,
            });
          }
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Something Went Wrong",
          });
        });
    },

    sendLoginsms: ({ commit }, data) => {
      axios.post("https://nhiftestapi.tilil.co.ke/send_sms", data);
      commit("SET_ALERT", null);
    },
  },
};
