<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="height: 100%; background-color: #eeeeee"
  >
    <v-card color="basil">
      <v-card-title class="text-center justify-center py-6">
        <h1 class="font-weight-bold text-h2 basil--text">PAY CONTRIBUTIONS</h1>
      </v-card-title>

      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-row v-if="tab === 0"
          ><v-col></v-col
          ><v-col><Payforself :checkprofile="checkprofile" /></v-col
          ><v-col></v-col
        ></v-row>

        <v-row v-if="tab === 1"
          ><v-col></v-col><v-col><payforOthersVue /></v-col><v-col></v-col
        ></v-row>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import Payforself from "./payforself.vue";
import payforOthersVue from "./payforOthers.vue";
import { AuthService } from "@/modules/auth";

export default {
  name: "contMain",
  components: { Payforself, payforOthersVue },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Profile/getProfile", {
        id_number: AuthService.idNo,
        source: "WEB",
        type: "1",
      });
    });
  },
  data() {
    return {
      items: ["Pay for Myself", "Pay for Other"],
      tab: null,
      checkprofile: false,
    };
  },
  watch: {
    tab: {
      handler: function () {
        if (this.tab === 0) {
          this.checkprofile = true;
        }
      },
    },
  },
};
</script>
