<template>
  <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="height: 100%; background-color: #eeeeee"
  >
    <v-card flat class="mt-1">
      <v-data-table
        :headers="headers"
        :items="preauths ? preauths : []"
        class="elevation-0 mb-3"
        :loading="isLoading"
        :items-per-page="13"
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.preauth_ref`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
            >{{ item.preauth_ref }}</span
          >
        </template>
       
        <template v-slot:[`item.patient_name`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
            >{{ makeEachLetterCaps(item.patient_name) }}</span
          >
        </template>
         <template v-slot:[`item.preauth_date`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
            >{{ item.preauth_date }}</span
          >
        </template>
        <template v-slot:[`item.facility_name`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
            >{{ item.facility_name }}</span
          >
        </template>

        <template v-slot:[`item.procedure`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
            >{{ item.procedure !== "" ? item.procedure : "N/A" }}</span
          >
        </template>

        <template v-slot:[`item.preauth_status`]="{ item }">
          <v-chip
            :color="statusColor(item.preauth_status)"
            x-small
            class="hos-name"
            ><span class="white--text">{{ item.preauth_status }}</span></v-chip
          >
        </template>

        <template v-slot:[`item.remarks`]="{ item }">
          <span
            :class="$vuetify.breakpoint.mdAndUp ? 'dep-name' : 'mob-dep-name'"
            >{{ item.remarks }}</span
          >
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="title-font-style"
              >Preauth Status (LOU)</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "preauth",
  components: {},

  beforeRouteEnter(to, from, next) {
    next(() => {});
  },
  data() {
    return {
      page: 1,
      isLoading: true,
      itemsPerPage: 10,
      headers: [
        { text: "LOU Number", value: "preauth_ref" },
        { text: "Date", value: "preauth_date" },
        {
          text: "Patient Name",
          align: "start",
          sortable: false,
          value: "patient_name",
        },
        { text: "Facility", value: "facility_name" },
        { text: "Procedure", value: "procedure" },
        { text: "Status", value: "preauth_status" },
        { text: "Remarks", value: "remarks" },
      ],
    };
  },

  computed: {
    preauths() {
      return this.$store.getters["Preauth/preauthGetters"]("preauth");
    },
    pages() {
      return Math.ceil(this.preauths.length / this.itemsPerPage);
    },
    paginatedItems() {
      const startIndex = (this.page - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.items.slice(startIndex, endIndex);
    },
    routeParams() {
      return atob(this.$route.query.q);
    },
    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
  },
  methods: {
    makeEachLetterCaps(val) {
      const d = val.toLowerCase();
      const a = d.charAt(0).toUpperCase() + d.slice(1);
      return a;
    },
    getColor(str) {
      var color_codes = {};
      return str in color_codes
        ? color_codes[str]
        : (color_codes[str] =
            "#" +
            ("000000" + ((Math.random() * 0xffffff) << 0).toString(16)).slice(
              -6
            ));
    },
    firstLetter(val) {
      let text1 = val.patient_name;
      var matches = text1.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(""); // JSON

      // console.log(acronym);
      return acronym;
    },
    statusColor(val) {
      if (val === "Approved") {
        return "green";
      } else {
        if (val === "Current") {
          return "teal";
        } else {
          return "red";
        }
      }
    },
  },
  watch: {
    profile: {
      handler: function () {
        if (this.profile !== undefined) {
          const data = {
            source: "WEB",
            member_no: this.profile.member_number,
          };
          this.$store.dispatch("Preauth/getPreauth", data);
        }
      },
    },
  },
  mounted() {
    setInterval(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
