<template>
  <v-container>
    <v-card class="mx-4" width="1000" flat>
      <v-card-text class="text-justify mt-2 pb-0">
        <v-form
          v-model="isValid"
          ref="paymentForm"
          class="mt-1"
          v-on:submit.prevent
        >
          <v-text-field
            dense
            outlined
            label="Name"
            placeholder=""
            v-model="formData.name"
            ref="phone"
            :rules="rules.required"
            readonly
          />
          <v-text-field
            dense
            outlined
            label="ID Number"
            placeholder=""
            v-model="formData.id_number"
            ref="idNo"
            :rules="rules.required"
            readonly
          />

          <v-text-field
            dense
            outlined
            label="Phone"
            placeholder=""
            v-model="formData.phone"
            required
            :rules="rules.required"
          />

          <v-text-field
            dense
            outlined
            label="Amount"
            ref="payeeamount"
            placeholder="Enter Amount to Pay"
            v-model="formData.amount"
            :rules="rules.amount"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="">
        <v-row>
          <v-col cols="4"> </v-col>
          <v-col cols="4">
            <v-btn
              class="btn-success btn-block white--text"
              color="success"
              @click="payNHIF()"
            >
              <span>Pay for Self</span>
            </v-btn>
          </v-col>
          <v-col cols="4"> </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="successDialog"
      max-width="550"
      max-height="1000"
      persistent
    >
      <v-card>
        <v-card-actions
          ><v-spacer /><v-icon
            class="vicon"
            size="100"
            :color="
              $store.getters['Pay/alert'].status === 'success'
                ? 'success'
                : 'error'
            "
          >
            {{
              $store.getters["Pay/alert"].status === "success"
                ? "mdi-check-circle-outline"
                : "mdi-close-circle-outline"
            }}</v-icon
          ><v-spacer
        /></v-card-actions>

        <v-card-title class="text-h6"
          ><v-spacer /> {{ $store.getters["Pay/alert"].message }}
          <v-spacer />
        </v-card-title>

        <v-card-actions>
          <v-spacer />

          <v-btn color="green darken-1 white--text" @click="redirect()">
            ok
          </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
// import { AuthService } from "@/modules/auth";

export default {
  name: "penSelf",

  beforeRouteEnter(to, from, next) {
    next(() => {});
  },
  props: {
    checkprofile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: false,
      successDialog: false,
      formData: {
        name: "",
        id_number: "",
        phone: "",
        amount: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
        amount: [
          (value) => !!value || "Amount is required.",
          (value) => (value <= 6000 && value > 0) || "Amount cannot be more than 6000.",
        ],
      },
    };
  },
  computed: {
    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
  },
  methods: {
    redirect() {
      // this.$router.push({ name: "mainDashboard" });
      window.location.reload();
    },
    payNHIF() {
      if (!this.isValid) {
        this.$refs.paymentForm.validate();
        this.$toast.error("All Fields Are Required");
      } else {
        this.$store.dispatch(
          "Pay/payNhif",

          {
            clientMSISDN: this.formData.phone,
            amount: this.formData.amount,
            pay_by_id: this.formData.id_number,
            clientIDNumber: this.formData.id_number,
            billDesc: "PENALTIES",
            clientName: this.formData.name,
            clientEmail: "",
            source: "WEB",
          }
        );
      }
    },
  },
  mounted() {
    EventBus.$on("successMessage", () => {
      //   this.$toast.success(e);
      this.successDialog = true;
    });

    EventBus.$on("ErrorMessage", () => {
      // this.$toast.error(e);
      this.successDialog = true;
    });
  },
  watch: {
    profile: {
      handler: function () {
        if (
          this.profile &&
          this.profile.first_name !== undefined &&
          this.checkprofile
        ) {
          this.formData.id_number = this.profile.id_number;
          this.formData.phone = this.profile.phone;
          this.formData.name =
            this.profile.first_name + " " + this.profile.last_name;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.pay-btn {
  color: white;
  width: 300px;
}
.title {
  font-size: 13px;
  font-weight: 800;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.vicon {
  /* font-size: 4px; */
  font-weight: 100;
}
</style>
