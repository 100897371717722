<template>
  <v-container>
    <v-card class="mx-4" width="1000" flat>
      <v-card-text class="text-justify mt-2 pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          class="mt-1"
          v-on:submit.prevent
        >
          <v-text-field
            dense
            outlined
            label="ID Number"
            placeholder=""
            v-model="otherFormData.id_number"
            ref="idNo"
            :rules="rules.required"
            @change="getNames(otherFormData.id_number)"
          />
          <v-text-field
            dense
            outlined
            label="Name"
            placeholder=""
            v-model="otherFormData.name"
            ref="phone"
            :rules="rules.required"
          />

          <v-text-field
            dense
            outlined
            label="Phone"
            placeholder=""
            v-model="otherFormData.phone"
            ref="phone"
            :rules="rules.required"
          />

          <v-text-field
            dense
            outlined
            label="Amount"
            placeholder="Enter Amount to Pay"
            v-model="otherFormData.amount"
            ref="amount"
            :rules="rules.amount"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="">
        <v-row>
          <v-col cols="4"> </v-col>
          <v-col cols="4">
            <v-btn
              class="btn-success btn-block white--text"
              color="success"
              @click="payForOther()"
            >
              <span>Pay for Other</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" persistent width="500">
      <v-card color="primary" dark>
        <v-card-text>
          Please Wait as We Retrieve the Names
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successDialog"
      max-width="550"
      max-height="1000"
      persistent
    >
      <v-card>
        <v-card-actions
          ><v-spacer /><v-icon
            class="vicon"
            size="100"
            :color="
              $store.getters['Pay/alert'].status === 'success'
                ? 'success'
                : 'error'
            "
          >
            {{
              $store.getters["Pay/alert"].status === "success"
                ? "mdi-check-circle-outline"
                : "mdi-close-circle-outline"
            }}</v-icon
          ><v-spacer
        /></v-card-actions>

        <v-card-title class="text-h6"
          ><v-spacer /> {{ $store.getters["Pay/alert"].message }}
          <v-spacer />
        </v-card-title>

        <v-card-actions>
          <v-spacer />

          <v-btn color="green darken-1 white--text" @click="redirect()">
            ok
          </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "@/utils/eventBus";

export default {
  name: "arrOthers",
  components: {},

  beforeRouteEnter(to, from, next) {
    next(() => {});
  },
  data() {
    return {
      isFormValid: false,

      dialog: false,
      successDialog: false,

      otherFormData: {
        name: "",
        id_number: "",
        phone: "",
        amount: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
        amount: [
          (value) => !!value || "Amount is required.",
          (value) => (value <= 6000 && value > 0) || "Amount cannot be more than 6000.",
        ],
      },
    };
  },
  computed: {
    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
    names() {
      return this.$store.getters["Pay/payGetters"]("names");
    },
  },
  methods: {
    redirect() {
      // this.$router.push({ name: "mainDashboard" });
      window.location.reload();
    },
    reset() {
      // this.$refs.form.reset();
      this.$refs["form"][0].reset();
    },
    getNames(val) {
      this.dialog = true;
      const data = {
        national_id: val,
        source: "WEB",
      };
      this.$store.dispatch("Pay/getNames", data);
    },

    payForOther() {
      if (!this.isFormValid) {
        this.$refs.form.validate();
        this.$toast.error("All Fields Are Required");
      } else {
        const newPhone = this.otherFormData.phone.replace(/^0|254/g, "");
        const NP = "254" + newPhone;
        this.$store.dispatch(
          "Pay/payNhif",

          {
            clientMSISDN: NP,
            amount: this.otherFormData.amount,
            pay_by_id: this.profile.id_number,
            clientIDNumber: this.otherFormData.id_number,
            billDesc: "ARREARS",
            clientName: this.otherFormData.name,
            clientEmail: "",
            source: "WEB",
          }
        );
      }
    },
  },
  mounted() {
    EventBus.$on("successMessage", () => {
      this.successDialog = true;
    });

    EventBus.$on("ErrorMessage", () => {
      this.successDialog = true;
      // this.$toast.error(e);
    });
    EventBus.$on("closeDialog", () => {
      this.dialog = false;
    });
  },
  watch: {
    names: {
      handler: function () {
        if (this.names !== undefined) {
          this.otherFormData.name =
            this.names.first_name + " " + this.names.last_name;
          this.otherFormData.phone = this.names.phone;
        }
      },
    },
  },
};
</script>

<style scoped>
.pay-btn {
  color: white;
  width: 300px;
}
.title {
  font-size: 13px;
  font-weight: 800;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.vicon {
  /* font-size: 4px; */
  font-weight: 100;
}
</style>
